import { MetricsFilter } from "~/types/Metrics";
import { Provider } from "~/types/shared";
import { AdGroupAdTag, AdGroupAdTagResponse } from "~/types/Tagging";

export const useTaggingStore = defineStore({
  id: "tagging-store",
  state: () => {
    return {
      tags: [],
    } as {
      tags: AdGroupAdTag[];
    };
  },
  actions: {
    async createTag(dto: {
      clientId: number;
      name: string;
      color: string;
      category: string;
    }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { tag: AdGroupAdTagResponse };
      }>(`tagging/client/${dto.clientId}/tag`, {
        method: "POST",
        body: { name: dto.name, color: dto.color, category: dto.category },
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        this.tags.push(data.value.data.tag);
      }
      return this.tags;
    },

    async assignTagToAllAdsOfClient(dto: {
      clientId: number;
      tagIds: number[];
      filter?: MetricsFilter[][];
      provider?: Provider;
    }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { adIds: number[] };
      }>(`tagging/client/${dto.clientId}/tags`, {
        method: "POST",
        body: {
          tagIds: Array.isArray(dto.tagIds) ? dto.tagIds : undefined,
          filter: Array.isArray(dto.filter) ? dto.filter : undefined,
          provider: dto.provider,
        },
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        const creativeReportingStore = useCreativeReportingStore();
        const creativeComparisonStore = useCreativeComparisonStore();
        const launchReportStore = useLaunchReportStore();
        const testingLogStore = useTestingLogStore();
        creativeReportingStore.markAllAdsAsLabeled();
        for (const tagId of dto.tagIds) {
          const tag = this.tags.find((tag) => tag.id === tagId);
          if (!tag) {
            continue;
          }
          for (const adId of data.value.data.adIds) {
            creativeReportingStore.assignTagToAd({ adId, tag });
            creativeComparisonStore.assignTagToAd({ adId, tag });
            launchReportStore.assignTagToAd({ adId, tag });
            testingLogStore.assignTagToAd({ adId, tag });
          }
        }
      }
      return null;
    },

    async markAllAdsOfClientAsLabeled(dto: {
      clientId: number;
      filter?: MetricsFilter[][];
      provider?: Provider;
    }) {
      const { error } = await useDatAdsApiFetch(
        `tagging/client/${dto.clientId}/labeled`,
        {
          method: "POST",
          body: {
            filter: Array.isArray(dto.filter) ? dto.filter : undefined,
            provider: dto.provider,
          },
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      const creativeReportingStore = useCreativeReportingStore();
      creativeReportingStore.markAllAdsAsLabeled();
      return null;
    },

    async updateTag(dto: {
      tagId: number;
      name: string;
      color: string;
      category: string;
    }) {
      const { error } = await useDatAdsApiFetch(`tagging/tag/${dto.tagId}`, {
        method: "PATCH",
        body: { name: dto.name, color: dto.color, category: dto.category },
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      this.tags = this.tags.map((tag) => {
        if (tag.id === dto.tagId) {
          return {
            ...tag,
            name: dto.name,
            color: dto.color,
            category: dto.category,
          };
        }
        return tag;
      });
      const tag = this.tags.find((tag) => tag.id === dto.tagId);
      if (tag) {
        const creativeReportingStore = useCreativeReportingStore();
        const creativeComparisonStore = useCreativeComparisonStore();
        const launchReportStore = useLaunchReportStore();
        const testingLogStore = useTestingLogStore();
        creativeReportingStore.updateTagAcrossAds({ tag });
        creativeComparisonStore.updateTagAcrossAds({ tag });
        launchReportStore.updateTagAcrossAds({ tag });
        testingLogStore.updateTagAcrossAds({ tag });
      }
    },

    async deleteTag(dto: { tagId: number }) {
      const { error } = await useDatAdsApiFetch(`tagging/tag/${dto.tagId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      this.tags = this.tags.filter((tag) => tag.id !== dto.tagId);
      const creativeReportingStore = useCreativeReportingStore();
      const creativeComparisonStore = useCreativeComparisonStore();
      const launchReportStore = useLaunchReportStore();
      const testingLogStore = useTestingLogStore();
      creativeReportingStore.removeTagAcrossAds({ tagId: dto.tagId });
      creativeComparisonStore.removeTagAcrossAds({ tagId: dto.tagId });
      launchReportStore.removeTagAcrossAds({ tagId: dto.tagId });
      testingLogStore.removeTagAcrossAds({ tagId: dto.tagId });
    },

    async removeTagFromAd(dto: { tagId: number; adId: number }) {
      const { error } = await useDatAdsApiFetch(
        `tagging/tag/${dto.tagId}/ad/${dto.adId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      const creativeReportingStore = useCreativeReportingStore();
      const creativeComparisonStore = useCreativeComparisonStore();
      const launchReportStore = useLaunchReportStore();
      const testingLogStore = useTestingLogStore();
      creativeReportingStore.removeTagFromAd({
        adId: dto.adId,
        tagId: dto.tagId,
      });
      creativeComparisonStore.removeTagFromAd({
        adId: dto.adId,
        tagId: dto.tagId,
      });
      launchReportStore.removeTagFromAd({
        adId: dto.adId,
        tagId: dto.tagId,
      });
      testingLogStore.removeTagFromAd({
        adId: dto.adId,
        tagId: dto.tagId,
      });
    },

    async removeTagFromCreative(dto: { tagId: number; creativeId: string }) {
      const { error } = await useDatAdsApiFetch(
        `tagging/tag/${dto.tagId}/creative/${dto.creativeId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      const creativeReportingStore = useCreativeReportingStore();
      const creativeComparisonStore = useCreativeComparisonStore();
      const launchReportStore = useLaunchReportStore();
      const testingLogStore = useTestingLogStore();
      creativeReportingStore.removeTagFromCreative({
        creativeId: dto.creativeId,
        tagId: dto.tagId,
      });
      creativeComparisonStore.removeTagFromCreative({
        creativeId: dto.creativeId,
        tagId: dto.tagId,
      });
      launchReportStore.removeTagFromCreative({
        creativeId: dto.creativeId,
        tagId: dto.tagId,
      });
      testingLogStore.removeTagFromCreative({
        creativeId: dto.creativeId,
        tagId: dto.tagId,
      });
    },

    async listTagsOfClient(dto: { clientId: number }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { tags: AdGroupAdTagResponse[] };
      }>(`tagging/client/${dto.clientId}`, {
        method: "GET",
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        this.tags = data.value.data.tags;
      }
      return this.tags;
    },

    async listTagsOfAd(dto: { adId: number }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { tags: AdGroupAdTagResponse[] };
      }>(`tagging/ad/${dto.adId}/tags`, {
        method: "GET",
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        return data.value.data.tags;
      }
      return [];
    },

    async listTagsOfCreative(dto: { clientId: number; creativeId: string }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { tags: AdGroupAdTagResponse[] };
      }>(`tagging/client/${dto.clientId}/creative/${dto.creativeId}/tags`, {
        method: "GET",
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        return data.value.data.tags;
      }
      return [];
    },
  },
  getters: {},
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaggingStore, import.meta.hot));
}
