import { defineStore } from "pinia";
import { getBatchStartEnd, sortAscending } from "~/shared/utils";
import { GetOverrides } from "~/types/AnyCreativeReport";
import { ClientLayout, ClientTargetMetric } from "~/types/Client";
import { Creative, CreativeResponse } from "~/types/Creative";
import {
  CreativeComparisonCategoryResponse,
  CreativeComparisonGroup,
  CreativeComparisonGroupResponse,
  CreativeComparisonGroupSuggestion,
  CreativeComparisonGroupSuggestionResponse,
  CreativeComparisonReport,
  CreativeComparisonReportResponse,
  CreativeComparisonView,
  CreativeComparisonViewResponse,
} from "~/types/CreativeComparison";
import { CustomMetricRule, MetricsFilter } from "~/types/Metrics";
import { ReportSnapshotResponse } from "~/types/ReportSnapshot";
import {
  AttributionWindow,
  GroupBy,
  Provider,
  Sort,
  SortBy,
  Timeframe,
  ViewType,
} from "~/types/shared";
import { AdGroupAdTag } from "~/types/Tagging";

export const useCreativeComparisonStore = defineStore({
  id: "creative-comparison-store",
  state: () => {
    return {
      reports: [],
      groupSuggestions: [],
      customConversionNames: {},
      customMetricRules: [],
      colorCodingLayout: ClientLayout.RELATIVE,
      targetMetrics: [],
      currency: "EUR",
    } as {
      reports: CreativeComparisonReport[];
      groupSuggestions: CreativeComparisonGroupSuggestion[];
      customConversionNames: Record<string, string>;
      customMetricRules: CustomMetricRule[];
      colorCodingLayout: ClientLayout;
      targetMetrics: ClientTargetMetric[];
      currency: string;
    };
  },
  actions: {
    async createReport(input: {
      title: string | null;
      clientId: number;
      description: string | undefined | null;
      meta: boolean;
      tiktok: boolean;
      youtube: boolean;
      folderId: number | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: CreativeComparisonReportResponse };
      }>("/creative-comparison/create", {
        method: "POST",
        body: {
          title: input.title === "" ? null : input.title,
          description: input.description === "" ? null : input.description,
          clientId: input.clientId,
          meta: input.meta,
          tiktok: input.tiktok,
          folderId: input.folderId,
          youtube: input.youtube,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeComparisonReports } = useCreativeComparison();
        const report = getMappedCreativeComparisonReports([
          data.value.data.report,
        ])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async duplicateReport(input: {
      reportId: number;
      clientId: number;
      folderId: number | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: CreativeComparisonReportResponse };
      }>(`creative-comparison/duplicate`, {
        method: "POST",
        body: {
          clientId: input.clientId,
          reportId: input.reportId,
          folderId: input.folderId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeComparisonReports } = useCreativeComparison();
        const report = getMappedCreativeComparisonReports([
          data.value.data.report,
        ])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async createGroup(input: {
      title: string;
      viewId: number;
      filter?: Array<Array<MetricsFilter>> | undefined | null;
      categoryId?: number | undefined | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          view: CreativeComparisonViewResponse;
          group: CreativeComparisonGroupResponse;
        };
      }>(`creative-comparison/group/create`, {
        method: "POST",
        body: {
          title: input.title,
          viewId: input.viewId,
          filter: input.filter,
          categoryId: input.categoryId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const {
          getMappedCreativeComparisonGroups,
          getMappedCreativeComparisonViews,
        } = useCreativeComparison();
        const group = getMappedCreativeComparisonGroups([
          data.value.data.group,
        ])[0];
        const view = getMappedCreativeComparisonViews([
          data.value.data.view,
        ])[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView(view, reportIdx);
        return group;
      }
      return null;
    },

    async createSnapshot(dto: { reportId: number }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<CreativeComparisonReportResponse>;
        };
      }>("/creative-comparison/snapshot", {
        method: "POST",
        body: { reportId: dto.reportId },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeComparisonReportSnapshpts } =
          useCreativeComparison();
        const snapshot = getMappedCreativeComparisonReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        return snapshot;
      }
      return null;
    },

    async updateReportMetadata(input: {
      reportId: number;
      title?: string | undefined | null;
      description?: string | undefined | null;
      selectedViewType?: ViewType | undefined | null;
      colorCodingLayout?: ClientLayout | undefined | null;
      showCreationDateColumn?: boolean | undefined | null;
      showTagColumn?: boolean | undefined | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `creative-comparison/report-metadata/${input.reportId}`,
        {
          method: "PATCH",
          body: {
            title: input.title === "" ? null : input.title,
            description: input.description === "" ? null : input.description,
            selectedViewType: input.selectedViewType,
            colorCodingLayout: input.colorCodingLayout,
            showCreationDateColumn: input.showCreationDateColumn,
            showTagColumn: input.showTagColumn,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const reportIndex = this.reports.findIndex(
        (report) => report.id === input.reportId,
      );
      if (reportIndex !== -1) {
        this.reports.splice(reportIndex, 1, {
          ...this.reports[reportIndex],
          title: input.title ?? this.reports[reportIndex].title,
          description:
            input.description ?? this.reports[reportIndex].description,
          selectedViewType:
            input.selectedViewType ??
            this.reports[reportIndex].selectedViewType,
          colorCodingLayout:
            input.colorCodingLayout ??
            this.reports[reportIndex].colorCodingLayout,
          showCreationDateColumn:
            input.showCreationDateColumn ??
            this.reports[reportIndex].showCreationDateColumn,
          showTagColumn:
            input.showTagColumn ?? this.reports[reportIndex].showTagColumn,
        });
      }
    },

    async updateReport(input: {
      reportId: number;
      timeframe?: Timeframe | undefined | null;
      startDate?: string | undefined | null;
      endDate?: string | undefined | null;
      returnProvider: Provider;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: CreativeComparisonReportResponse;
          customConversionNames: Record<string, string>;
          customMetricRules: CustomMetricRule[];
        };
      }>(`creative-comparison/${input.reportId}`, {
        method: "PATCH",
        body: {
          timeframe: input.timeframe ?? undefined,
          startDate: input.startDate ?? undefined,
          endDate: input.endDate ?? undefined,
          returnProvider: input.returnProvider,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.customMetricRules = data.value.data.customMetricRules;
        const { getMappedCreativeComparisonReports } = useCreativeComparison();
        const report = getMappedCreativeComparisonReports([
          data.value.data.report,
        ])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async updateView(input: {
      viewId: number;
      primaryMetric?: string;
      gridMetrics?: Array<string>;
      tableMetrics?: Array<string>;
      filter?: Array<Array<MetricsFilter>> | null;
      sort?: Sort;
      sortBy?: SortBy;
      creativeSort?: Sort;
      creativeSortBy?: SortBy;
      selectedGroupIds?: Array<number> | null;
      leftYAxisMetric?: string | null;
      rightYAxisMetric?: string | null;
      attributionWindow?: AttributionWindow | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { view: CreativeComparisonViewResponse };
      }>(`creative-comparison/view/${input.viewId}`, {
        method: "PATCH",
        body: {
          primaryMetric: input.primaryMetric ?? undefined,
          gridMetrics: input.gridMetrics ?? undefined,
          tableMetrics: input.tableMetrics ?? undefined,
          filter: input.filter,
          sort: input.sort ?? undefined,
          sortBy: input.sortBy ?? undefined,
          selectedGroupIds: input.selectedGroupIds,
          leftYAxisMetric: input.leftYAxisMetric,
          rightYAxisMetric: input.rightYAxisMetric,
          attributionWindow: input.attributionWindow,
          creativeSort: input.creativeSort ?? undefined,
          creativeSortBy: input.creativeSortBy ?? undefined,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeComparisonViews } = useCreativeComparison();
        const view = getMappedCreativeComparisonViews([
          data.value.data.view,
        ])[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView(view, reportIdx);
        return view;
      }
      return null;
    },

    async updateGroup(input: {
      groupId: number;
      title?: string | undefined | null;
      filter?: Array<Array<MetricsFilter>> | undefined | null;
      selectedCreativeIds?: Array<number> | undefined | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          view: CreativeComparisonViewResponse;
          group: CreativeComparisonGroupResponse;
        };
      }>(`creative-comparison/group/${input.groupId}`, {
        method: "PATCH",
        body: {
          title: input.title === "" ? null : input.title,
          filter: input.filter,
          selectedCreativeIds: input.selectedCreativeIds,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const {
          getMappedCreativeComparisonGroups,
          getMappedCreativeComparisonViews,
        } = useCreativeComparison();
        const group = getMappedCreativeComparisonGroups([
          data.value.data.group,
        ])[0];
        const view = getMappedCreativeComparisonViews([
          data.value.data.view,
        ])[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView(view, reportIdx);
        return group;
      }
      return null;
    },

    async updateGroupsSortOrder(
      dto: Array<{
        groupId: number;
        sortOrder: number;
        categoryId: number | null;
      }>,
      viewId: number,
    ) {
      // Optimistic update, update group position in array
      const reportIdx = this.reports.findIndex((report) =>
        report.views.some((view) => view.info.id === viewId),
      );
      if (reportIdx === -1) {
        return null;
      }
      const viewIdx = this.reports[reportIdx].views.findIndex(
        (view) => view.info.id === viewId,
      );
      if (viewIdx === -1) {
        return null;
      }
      const groups = this.reports[reportIdx].views[viewIdx].groups;
      for (const { groupId, sortOrder, categoryId } of dto) {
        const index = groups.findIndex((g) => g.info.id === groupId);
        if (index === -1) {
          continue;
        }
        const group = groups[index];
        this.reports[reportIdx].views[viewIdx].groups.splice(index, 1, {
          ...group,
          info: { ...group.info, sortOrder, categoryId },
        });
      }
      this.reports[reportIdx].views[viewIdx].groups.sort((a, b) =>
        sortAscending(a.info.sortOrder, b.info.sortOrder),
      );
      const { error } = await useDatAdsApiFetch(
        `creative-comparison/group/sort-order`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async getReport(
      input: {
        reportUuid: string;
        provider?: Provider;
        resolveAssets?: boolean;
      } & GetOverrides<number>,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: CreativeComparisonReportResponse;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`creative-comparison/report/${input.reportUuid}`, {
        query: {
          ...getOverridesQuery(input),
          provider: input.provider,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedCreativeComparisonReports } = useCreativeComparison();
        const report = getMappedCreativeComparisonReports([
          data.value.data.report,
        ])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async getReportSnapshot(dto: { reportUuid: string }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<CreativeComparisonReportResponse>;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`creative-comparison/report/${dto.reportUuid}/snapshot`);
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedCreativeComparisonReportSnapshpts } =
          useCreativeComparison();
        const snapshot = getMappedCreativeComparisonReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        return snapshot;
      }
      return null;
    },

    async getView(
      input: {
        viewUuid: string;
        resolveAssets?: boolean;
      } & GetOverrides<number>,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          view: CreativeComparisonViewResponse;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`creative-comparison/view/${input.viewUuid}`, {
        query: {
          ...getOverridesQuery(input),
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedCreativeComparisonViews } = useCreativeComparison();
        const view = getMappedCreativeComparisonViews([
          data.value.data.view,
        ])[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        this.setView(view, reportIdx);
      }
      return null;
    },

    async getGroup(
      input: {
        groupUuid: string;
        viewUuid: string;
        resolveAssets?: boolean;
        creativePageNumber: number;
        creativePageSize: number;
      } & GetOverrides<number>,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: { group: CreativeComparisonGroup };
      }>(`creative-comparison/group/${input.groupUuid}`, {
        query: {
          ...getOverridesQuery(input),
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeComparisonGroups } = useCreativeComparison();
        const group = getMappedCreativeComparisonGroups([
          data.value.data.group,
        ])[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        const groups = this.reports[reportIdx].views[viewIdx].groups;
        const index = groups.findIndex((g) => g.info.id === group.info.id);
        if (index === -1) {
          groups.push(group);
        } else {
          groups.splice(index, 1, group);
        }
        return group;
      }
      return null;
    },

    async listReports(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { reports: CreativeComparisonReportResponse[] };
      }>(`creative-comparison/client/${clientId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedCreativeComparisonReports } = useCreativeComparison();
        const reports = getMappedCreativeComparisonReports(
          data.value.data.reports,
        );
        this.setReports(reports);
      }
      return this.reports.filter((report) => report.clientId === clientId);
    },

    setReports(reports: CreativeComparisonReport[]) {
      // Remove all reports not in returned list
      this.reports = this.reports.filter((report) =>
        reports.some((r) => r.id === report.id),
      );
      // Add or update reports
      reports.forEach((r) => this.setReport(r));
    },

    async listGroups(
      input: {
        viewUuid: string;
        resolveAssets?: boolean;
      } & GetOverrides<number>,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: { groups: Array<CreativeComparisonGroup> };
      }>(`creative-comparison/group/${input.viewUuid}/list`, {
        query: {
          ...getOverridesQuery(input),
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedCreativeComparisonGroups } = useCreativeComparison();
        const groups = getMappedCreativeComparisonGroups(
          data.value.data.groups,
        );
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        groups.forEach((group, idx) =>
          this.setGroup({
            group,
            reportIdx,
            viewIdx,
            batchIdx: idx,
          }),
        );
        return groups;
      }
      return [];
    },

    async listCreativesOfGroup(
      input: {
        groupUuid: string;
        viewUuid: string;
        pageNumber: number;
        pageSize: number;
        resolveAssets?: boolean;
      } & GetOverrides<number>,
    ) {
      const { hasFetchedCreatives, creativesBatch } =
        this.getFetchedCreatives(input);
      if (hasFetchedCreatives) {
        return creativesBatch;
      }
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: { creatives: CreativeResponse[] };
      }>(`creative-comparison/group/${input.groupUuid}/list-creatives`, {
        query: {
          ...getOverridesQuery(input),
          viewUuid: input.viewUuid,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        const { getMappedCreatives } = useCreatives();
        const creatives = getMappedCreatives(data.value.data.creatives);
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        const groupIdx = this.reports[reportIdx].views[
          viewIdx
        ].groups.findIndex((group) => group.info.uuid === input.groupUuid);
        creatives.forEach((creative, idx) =>
          this.setCreative({
            creative,
            reportIdx,
            viewIdx,
            groupIdx,
            pageNumber: input.pageNumber,
            pageSize: input.pageSize,
            batchIdx: idx,
          }),
        );
        return creatives;
      }
      return [];
    },

    async resolveGroupAssets(dto: {
      groups: CreativeComparisonGroup[];
      slice: number | undefined;
    }) {
      const { resolveGroupAssets } = useAssets();
      const groupsWithAssets = await resolveGroupAssets({
        groups: dto.groups,
        slice: dto.slice,
        groupBy: GroupBy.NONE,
      });
      const { getMappedCreativeComparisonGroups } = useCreativeComparison();
      const mappedGroups = getMappedCreativeComparisonGroups(groupsWithAssets);
      for (const report of this.reports) {
        for (const view of report.views) {
          view.groups = view.groups.map((group) => {
            const groupWithAssets = mappedGroups.find(
              (g) => g.info.id === group.info.id,
            );
            return groupWithAssets ?? group;
          });
        }
      }
    },

    getFetchedCreatives(input: {
      groupUuid: number | string;
      viewUuid: string;
      pageNumber: number;
      pageSize: number;
    }) {
      const reportIdx = this.reports.findIndex((report) =>
        report.views.some((v) => v.info.uuid === input.viewUuid),
      );
      const viewIdx = this.reports[reportIdx].views.findIndex(
        (view) => view.info.uuid === input.viewUuid,
      );
      const groupIdx = this.reports[reportIdx].views[viewIdx].groups.findIndex(
        (group) => group.info.uuid === input.groupUuid,
      );
      const creatives =
        this.reports[reportIdx].views[viewIdx].groups[groupIdx].creatives;
      const total =
        this.reports[reportIdx].views[viewIdx].groups[groupIdx].total;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
        total,
      });
      const hasFetchedCreatives =
        creatives.length >= end &&
        creatives.every((c) => c.info.metrics.length > 0);
      if (!hasFetchedCreatives) {
        return {
          hasFetchedGroups: false,
          groupsBatch: [],
        };
      }
      const creativesBatch = creatives.slice(start, end);
      const { allCreativesNotEmpty } = useCreatives();
      return {
        hasFetchedCreatives: allCreativesNotEmpty(creativesBatch),
        creativesBatch,
      };
    },

    setReport(report: CreativeComparisonReport) {
      const index = this.reports.findIndex((r) => r.id === report.id);
      if (index === -1) {
        this.reports.push(report);
      } else {
        report.views.forEach((view) => this.setView(view, index));
        // Keep views
        this.reports.splice(index, 1, {
          ...report,
          views: this.reports[index].views,
        });
      }
    },

    setView(view: CreativeComparisonView, reportIdx: number) {
      const views = this.reports[reportIdx].views;
      const index = views.findIndex((v) => v.info.id === view.info.id);
      if (index === -1) {
        views.push(view);
      } else {
        // Override groups, otherwise new sorting not reflected
        // To paginate groups, use listGroups method
        views.splice(index, 1, view);
      }
    },

    setGroup(input: {
      group: CreativeComparisonGroup;
      reportIdx: number;
      viewIdx: number;
      batchIdx: number; // Index of group in batch
    }) {
      // There is no pagination on group level, thus we splice every group
      const groups = this.reports[input.reportIdx].views[input.viewIdx].groups;
      groups.splice(input.batchIdx, 1, input.group);
    },

    setCreative(input: {
      creative: Creative;
      reportIdx: number;
      viewIdx: number;
      groupIdx: number;
      pageNumber: number;
      pageSize: number;
      batchIdx: number; // Index of creative in batch
    }) {
      const { getEmptyCreative, isEmptyCreative } = useCreatives();

      const creatives =
        this.reports[input.reportIdx].views[input.viewIdx].groups[
          input.groupIdx
        ].creatives;
      const total =
        this.reports[input.reportIdx].views[input.viewIdx].groups[
          input.groupIdx
        ].total;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
        total,
      });
      const creativesBatch = creatives.slice(start, end);

      // If the user clicks on a page in the middle of the list
      // we need to fill the previous pages with empty creatives
      // Those get replaced by the actual creatives when we fetch them
      if (creatives.length < start) {
        for (let i = creatives.length; i < start; i++) {
          creatives.push(
            getEmptyCreative({
              idx: i,
            }),
          );
        }
      }

      // Replace empty creative with actual creative
      if (
        creativesBatch[input.batchIdx] != null &&
        isEmptyCreative(creativesBatch[input.batchIdx])
      ) {
        creatives.splice(start + input.batchIdx, 1, input.creative);
        return;
      }

      // Add creative to creatives
      creatives.splice(start + input.batchIdx, 0, input.creative);
    },

    async deleteReport(reportId: number) {
      const { error } = await useDatAdsApiFetch(
        `creative-comparison/${reportId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const index = this.reports.findIndex((report) => report.id === reportId);
      if (index !== -1) {
        this.reports.splice(index, 1);
      }
      return null;
    },

    async deleteGroup(groupId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          view: CreativeComparisonViewResponse;
        };
      }>(`creative-comparison/group/${groupId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeComparisonViews } = useCreativeComparison();
        const view = getMappedCreativeComparisonViews([
          data.value.data.view,
        ])[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView(view, reportIdx);
      }
      return null;
    },

    async listGroupSuggestions(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { suggestions: CreativeComparisonGroupSuggestionResponse[] };
      }>(`creative-comparison/group-suggestion/${clientId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedCreativeComparisonGroupSuggestions } =
          useCreativeComparison();
        this.groupSuggestions = getMappedCreativeComparisonGroupSuggestions(
          data.value.data.suggestions,
        );
      }
      return this.groupSuggestions;
    },

    async updateGroupSuggestion(
      dto: {
        suggestionId: number;
        reviewed: boolean;
      }[],
    ) {
      // Optimistic update
      dto.forEach((suggestion) => {
        const index = this.groupSuggestions.findIndex(
          (s) => s.id === suggestion.suggestionId,
        );
        if (index !== -1) {
          this.groupSuggestions.splice(index, 1, {
            ...this.groupSuggestions[index],
            reviewed: suggestion.reviewed,
          });
        }
      });
      const { error } = await useDatAdsApiFetch(
        `creative-comparison/group-suggestion`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    assignTagToAd(dto: { adId: number; tag: AdGroupAdTag }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              const idx = creative.info.tags.findIndex(
                (tag) => tag.id === dto.tag.id,
              );
              if (creative.info.id === dto.adId && idx === -1) {
                creative.info.tags.push(dto.tag);
              }
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    removeTagFromAd(dto: { adId: number; tagId: number }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              const idx = creative.info.tags.findIndex(
                (tag) => tag.id === dto.tagId,
              );
              if (creative.info.id === dto.adId && idx !== -1) {
                creative.info.tags.splice(idx, 1);
              }
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    removeTagFromCreative(dto: { creativeId: string; tagId: number }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              const idx = creative.info.tags.findIndex(
                (tag) => tag.id === dto.tagId,
              );
              if (creative.info.creativeId === dto.creativeId && idx !== -1) {
                creative.info.tags.splice(idx, 1);
              }
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    updateTagAcrossAds(dto: { tag: AdGroupAdTag }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              creative.info.tags = creative.info.tags.map((tag) =>
                tag.id === dto.tag.id ? dto.tag : tag,
              );
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    removeTagAcrossAds(dto: { tagId: number }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.groups = view.groups.map((group) => {
            group.creatives = group.creatives.map((creative) => {
              creative.info.tags = creative.info.tags.filter(
                (tag) => tag.id !== dto.tagId,
              );
              return creative;
            });
            return group;
          });
          return view;
        });
        return report;
      });
    },

    addCustomMetricRule(rule: CustomMetricRule) {
      this.customMetricRules.push(rule);
    },

    async updateCustomMetricRule(dto: {
      newRule: CustomMetricRule;
      oldRule: CustomMetricRule;
      viewId: number;
    }) {
      const { newRule, oldRule, viewId } = dto;
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === newRule.customMetricRuleId,
      );
      if (index < 0) {
        return;
      }
      this.customMetricRules.splice(index, 1, { ...newRule });
      const isRenamed = newRule.metricName !== oldRule.metricName;
      if (!isRenamed) {
        return;
      }
      await this.renameCustomMetricRule({
        newName: newRule.metricName,
        oldName: oldRule.metricName,
        viewId,
      });
    },

    async renameCustomMetricRule(dto: {
      newName: string;
      oldName: string;
      viewId: number;
    }) {
      const { newName, oldName, viewId } = dto;
      this.customMetricRules.forEach((rule) => {
        if (rule.metricName === oldName) {
          rule.metricName = newName;
        }
      });
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      if (view.info.primaryMetric === oldName) {
        view.info.primaryMetric = newName;
        await updateQuery({ primaryMetric: newName });
      }
      if (view.info.leftYAxisMetric === oldName) {
        view.info.leftYAxisMetric = newName;
        await updateQuery({ leftYAxisMetric: newName });
      }
      if (view.info.rightYAxisMetric === oldName) {
        view.info.rightYAxisMetric = newName;
        await updateQuery({ rightYAxisMetric: newName });
      }
      view.info.gridMetrics = view.info.gridMetrics.map((m) =>
        m === oldName ? newName : m,
      );
      await updateQuery({ gridMetrics: JSON.stringify(view.info.gridMetrics) });
      view.info.tableMetrics = view.info.tableMetrics.map((m) =>
        m === oldName ? newName : m,
      );
      await updateQuery({
        tableMetrics: JSON.stringify(view.info.tableMetrics),
      });
      if (view.info.filter != null) {
        const { renameFiltersWithCustomMetric } = useFilter();
        view.info.filter = renameFiltersWithCustomMetric({
          oldMetricName: oldName,
          newMetricName: newName,
          filter: view.info.filter,
        });
        await updateQuery({ filters: JSON.stringify(view.info.filter) });
      }
    },

    async deleteCustomMetricRule(ruleId: number, viewId: number) {
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === ruleId,
      );
      if (index < 0) {
        return;
      }
      const rule = { ...this.customMetricRules[index] };
      this.customMetricRules.splice(index, 1);
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      const gridIdx = view.info.gridMetrics.findIndex(
        (m) => m === rule.metricName,
      );
      if (gridIdx >= 0) {
        view.info.gridMetrics.splice(gridIdx, 1);
        await updateQuery({
          gridMetrics: JSON.stringify(view.info.gridMetrics),
        });
      }
      const tableIdx = view.info.tableMetrics.findIndex(
        (m) => m === rule.metricName,
      );
      if (tableIdx >= 0) {
        view.info.tableMetrics.splice(tableIdx, 1);
        await updateQuery({
          tableMetrics: JSON.stringify(view.info.tableMetrics),
        });
      }
      if (view.info.primaryMetric === rule.metricName) {
        const { getProviderDefaultMetric } = useMetrics();
        view.info.primaryMetric = getProviderDefaultMetric(view.info.provider);
        await updateQuery({ primaryMetric: view.info.primaryMetric });
      }
      if (view.info.leftYAxisMetric === rule.metricName) {
        view.info.leftYAxisMetric = null;
        await updateQuery({ leftYAxisMetric: null });
      }
      if (view.info.rightYAxisMetric === rule.metricName) {
        view.info.rightYAxisMetric = null;
        await updateQuery({ rightYAxisMetric: null });
      }
      if (view.info.filter != null) {
        const { removeFiltersWithCustomMetric } = useFilter();
        view.info.filter = removeFiltersWithCustomMetric({
          metricName: rule.metricName,
          filter: view.info.filter,
        });
        await updateQuery({ filters: JSON.stringify(view.info.filter) });
      }
    },

    async createCategory(dto: {
      viewId: number;
      title?: string | undefined | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          category: CreativeComparisonCategoryResponse;
        };
      }>(`creative-comparison/category/create`, {
        method: "POST",
        body: {
          viewId: dto.viewId,
          title: dto.title,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreativeComparisonCategories } =
          useCreativeComparison();
        const category = getMappedCreativeComparisonCategories([
          data.value.data.category,
        ])[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((view) => view.info.id === dto.viewId),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.id === dto.viewId,
        );
        this.setView(
          {
            ...this.reports[reportIdx].views[viewIdx],
            categories: [
              ...this.reports[reportIdx].views[viewIdx].categories,
              category,
            ],
          },
          reportIdx,
        );
      }
      return null;
    },

    async updateCategoryMetadata(dto: {
      categoryId: number;
      title?: string | undefined | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `creative-comparison/category/${dto.categoryId}/metadata`,
        {
          method: "PATCH",
          body: {
            title: dto.title,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      for (const report of this.reports) {
        for (const view of report.views) {
          view.categories = view.categories.map((category) =>
            category.id === dto.categoryId && dto.title != null
              ? { ...category, title: dto.title }
              : category,
          );
        }
      }
      return null;
    },

    async updateCategorySortOrder(
      dto: {
        categoryId: number;
        sortOrder: number;
      }[],
    ) {
      // Optimistic update, update category position in array
      for (const { categoryId, sortOrder } of dto) {
        for (const report of this.reports) {
          for (const view of report.views) {
            view.categories = view.categories
              .map((category) =>
                category.id === categoryId
                  ? { ...category, sortOrder }
                  : category,
              )
              .sort((a, b) => sortAscending(a.sortOrder, b.sortOrder));
          }
        }
      }
      const { error } = await useDatAdsApiFetch(
        `creative-comparison/category/sort-order`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async deleteCategory(categoryId: number) {
      const { error } = await useDatAdsApiFetch(
        `creative-comparison/category/${categoryId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      for (const report of this.reports) {
        for (const view of report.views) {
          view.categories = view.categories.filter(
            (category) => category.id !== categoryId,
          );
        }
      }
      return null;
    },
  },
  getters: {
    getReportsOfClient: (state) => (clientId: number) => {
      return state.reports.filter((report) => report.clientId === clientId);
    },

    getReportById: (state) => (reportId: number) => {
      return state.reports.find((report) => report.id === reportId) ?? null;
    },

    getReportByUuid: (state) => (reportUuid: string) => {
      return state.reports.find((report) => report.uuid === reportUuid) ?? null;
    },

    getSuggestionsOfClient:
      (state) =>
      (clientId: number, filterReviewed = false) => {
        const allSuggestions = state.groupSuggestions.filter(
          (suggestion) => suggestion.clientId === clientId,
        );
        if (filterReviewed) {
          return allSuggestions.filter((suggestion) => !suggestion.reviewed);
        }
        return allSuggestions;
      },

    getCategoryById: (state) => (categoryId: number) => {
      for (const report of state.reports) {
        for (const view of report.views) {
          const category = view.categories.find(
            (category) => category.id === categoryId,
          );
          if (category != null) {
            return category;
          }
        }
      }
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCreativeComparisonStore, import.meta.hot),
  );
}
